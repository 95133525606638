export default {
    en: {
        race: {
            chinese: "Chinese",
            malay: "Malay",
            indian: "Indian",
            others: "Others"
        },
        occupation: {
            self: "Self-Employed",
            homemaker: "Homemaker",
            student: "Student",
            employed: "Employed",
            others: "Others"
        }
    }
};