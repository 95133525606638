<template>
    <form class="ui form" :class="{loading: submitting}" @submit.prevent="submitForm">
        <div class="ui middle aligned grid">
            <div class="sixteen wide mobile eight wide tablet eight wide computer column">
                <h3 class="ui header">Personal Information</h3>
            </div>
            <div class="computer only tablet only right aligned eight wide column">
                <img class="logo" src="~@/assets/images/logo/1991spwg.png"/>
            </div>
        </div>

        <div class="ui hidden divider"></div>
        <div class="required two fields">
            <div class="field">
                <label>Name</label>
                <input type="text" v-model.trim="form.name" required/>
            </div>
            <div class="field">
                <label>Gender</label>
                <select class="ui dropdown" v-model="form.gender" required>
                    <option value=""></option>
                    <option :value="$gender.FEMALE">{{$t("common.female")}}</option>
                    <option :value="$gender.MALE">{{$t("common.male")}}</option>
                </select>
            </div>
        </div>

        <div class="required two fields">
            <div class="field">
                <label>NRIC. / Passport</label>
                <input ref="idnoinput" type="text" pattern="^(\d|\w)+$" placeholder="No space or special character" v-model.trim="form.id_no" required/>
            </div>
            <div class="field">
                <label>
                    Date of Birth <template v-if="form.dob">({{form.dob|formatdate("D MMM YYYY")}})</template>
                </label>
                <input type="date" :max="(new Date())|formatdate('YYYY-MM-DD')" v-model="form.dob" required/>
            </div>
        </div>

        <combo-dropdown label="Race" secondary-label="Other Race (Please Specify)" :selection="races" other-value="others" v-model="form.race" required/>

        <div class="required field">
            <label>Address</label>
            <textarea v-model="form.address" rows="3" required></textarea>
        </div>

        <div class="required two fields">
            <div class="field">
                <label>Email</label>
                <input ref="emailinput" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$" v-model.trim="form.email" required/>
            </div>
            <div class="field">
                <label>Mobile No.</label>
                <input ref="contactnoinput" type="tel" v-model.trim="form.contact_no" required/>
            </div>
        </div>

        <combo-dropdown label="Occupation" secondary-label="Other Occupation (Please Specify)" :selection="occupations" other-value="others" v-model="form.occupation" required/>

        <div class="required two fields">
            <div class="field">
                <label>Designation</label>
                <input type="text" v-model.trim="form.designation" required/>
            </div>
        </div>

        <template v-if="actions">
            <div class="ui hidden divider"></div>

            <div class="ui two column actions grid">
                <div class="column">
                    <button type="button" class="ui red alt button" :class="{loading: submitting}" :disabled="submitting" @click="cancel" v-if="actions.cancel">
                        {{actions.cancel.text || "Cancel"}}
                    </button>
                </div>
                <div class="right aligned column">
                    <button type="button" class="ui teal alt button" :class="{loading: submitting}" :disabled="submitting" @click="prev" v-if="actions.PREV">
                        <i class="fontello-left" style="margin-right: 0.5em;"></i> {{actions.prev.text || "Prev"}}
                    </button>
                    <button type="submit" class="ui teal button" :class="{loading: submitting}" :disabled="submitting" v-if="actions.next">
                        {{actions.next.text || "Next"}} <i class="fontello-right" style="margin-left: 0.5em;"></i>
                    </button>
                </div>
            </div>
        </template>
    </form>
</template>

<script>
import personalinfoMessage from "@/i18n/consultation/personalinfo";

const ComboDropdown = () => import("@/components/ComboDropdown");

export default {
    name: "personal-info-form",
    components: {
        ComboDropdown
    },
    i18n: {
        sharedMessages: personalinfoMessage
    },
    props: {
        actions: {
            type: Object
        },
        formdata: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        const blank_form = {
            name: "",
            gender: "",
            id_no: "",
            dob: "",
            race: "",
            address: "",
            email: "",
            contact_no: "",
            occupation: "",
            designation: ""
        };

        return {
            locale: window.navigator.userLanguage || window.navigator.language,
            races: {
                "chinese": this.$t("race.chinese"),
                "malay": this.$t("race.malay"),
                "indian": this.$t("race.indian"),
                "others": "Others"
            },
            occupations: {
                "self": this.$t("occupation.self"),
                "homemaker": this.$t("occupation.homemaker"),
                "student": this.$t("occupation.student"),
                "employed": this.$t("occupation.employed"),
                "others": "Others"
            },
            blank_form,
            form: Object.assign({}, blank_form),
            original_email: "",
            original_id_no: "",
            original_contact_no: "",
            submitting: false
        };
    },
    methods: {
        submitForm() {
            this.submitting = true;
            this.$toasted.clear();

            //check validity of email, id no, and contact no
            let checkEmail, checkIdNo, checkContactNo = null;
            if(this.original_email !== this.form.email) {
                checkEmail = this.$http.head(`customers/email/${this.form.email}`);
            } else {
                checkEmail = Promise.resolve();
            }

            if(this.original_id_no !== this.form.id_no) {
                checkIdNo = this.$http.head(`customers/id-no/${this.form.id_no}`);
            } else {
                checkIdNo = Promise.resolve();
            }

            if(this.original_contact_no !== this.form.contact_no) {
                checkContactNo = this.$http.head(`customers/contact-no/${this.form.contact_no}`);
            } else {
                checkContactNo = Promise.resolve();
            }

            Promise.allSettled([checkEmail, checkIdNo, checkContactNo]).then((results) => {
                const email_exists = results[0].status === "fulfilled";
                const idno_exists = results[1].status === "fulfilled";
                const contactno_exists = results[2].status === "fulfilled";

                let can_continue = true;
                if(email_exists) {
                    if(this.original_email !== this.form.email) {
                        can_continue = false;

                        this.$toasted.error(`Email ${this.form.email} already registered. Please use another email.`, {
                            action: {
                                text: "OK",
                                onClick: () => {
                                    setTimeout(() => {
                                        this.submitting = false;
                                        $(this.$refs.emailinput).focus().select();
                                    }, 0);

                                    this.$toasted.clear();
                                }
                            },
                            onComplete: () => {
                                this.submitting = false;
                                $(this.$refs.emailinput).focus().select();
                            }
                        });
                    }
                } else {
                    //check status code is 404
                    const status_code = results[0].reason.response?.status;
                    if(404 !== status_code) {
                        //meaning error
                        can_continue = false;
                        console.error(results[0].reason);

                        this.$toasted.error(results[0].reason.message || `Request failed with status code ${status_code}`, {
                            duration: 3000,
                            onComplete: () => {
                                this.submitting = false;
                            }
                        });
                    }
                }

                if(idno_exists) {
                    if(this.original_id_no !== this.form.id_no) {
                        can_continue = false;

                        this.$toasted.error(`NRIC. / Passport ${this.form.id_no} already registered. Please use another identification.`, {
                            action: {
                                text: "OK",
                                onClick: () => {
                                    setTimeout(() => {
                                        this.submitting = false;
                                        $(this.$refs.idnoinput).focus().select();
                                    }, 0);

                                    this.$toasted.clear();
                                }
                            },
                            onComplete: () => {
                                this.submitting = false;
                                $(this.$refs.idnoinput).focus().select();
                            }
                        });
                    }
                } else {
                    //check status code is 404
                    const status_code = results[1].reason.response?.status;
                    if(404 !== status_code) {
                        //meaning error
                        can_continue = false;
                        console.error(results[1].reason);

                        this.$toasted.error(results[1].reason.message || `Request failed with status code ${status_code}`, {
                            duration: 3000,
                            onComplete: () => {
                                this.submitting = false;
                            }
                        });
                    }
                }

                if(contactno_exists) {
                    if(this.original_contact_no !== this.form.contact_no) {
                        can_continue = false;

                        this.$toasted.error(`Mobile number ${this.form.contact_no} already registered. Please use another mobile number.`, {
                            action: {
                                text: "OK",
                                onClick: () => {
                                    setTimeout(() => {
                                        this.submitting = false;
                                        $(this.$refs.contactnoinput).focus().select();
                                    }, 0);

                                    this.$toasted.clear();
                                }
                            },
                            onComplete: () => {
                                this.submitting = false;
                                $(this.$refs.contactnoinput).focus().select();
                            }
                        });
                    }
                } else {
                    //check status code is 404
                    const status_code = results[2].reason.response?.status;
                    if(404 !== status_code) {
                        //meaning error
                        can_continue = false;
                        console.error(results[2].reason);

                        this.$toasted.error(results[2].reason.message || `Request failed with status code ${status_code}`, {
                            duration: 3000,
                            onComplete: () => {
                                this.submitting = false;
                            }
                        });
                    }
                }

                if(can_continue) {
                    this.$emit("submit", {
                        profile: this.form
                    });
                }
            });
        },
        cancel() {
            this.$emit("cancel");
        }
    },
    watch: {
        formdata: {
            deep: true,
            immediate: true,
            handler() {
                if(!this.isBlankObject(this.formdata)) {
                    this.form = Object.assign({}, this.blank_form, this.formdata);
                }

                if(this.formdata?.id) {
                    //meaning it's from editing
                    this.original_email = this.formdata.email;
                    this.original_id_no = this.formdata.id_no;
                    this.original_contact_no = this.formdata.contact_no;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/consultationform.scss";
</style>