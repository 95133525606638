<template>
    <form class="ui form" :class="{loading: submitting}" @submit.prevent="submitForm">
        <div class="ui middle aligned grid">
            <div class="sixteen wide mobile eight wide tablet eight wide computer column">
                <h3 class="ui header">
                    <template v-if="form.id">
                        Edit Consultation
                        <div class="ui fitted hidden divider"></div>
                        <div class="sub header"><strong class="ui purple text">{{customer_name}}</strong></div>
                    </template>
                    <template v-else>
                        Hi {{customer_name}}
                        <div class="sub header">Please tell us about your visit today</div>
                    </template>
                </h3>
            </div>
            <div class="computer only tablet only right aligned eight wide column">
                <img class="logo" src="~@/assets/images/logo/1991spwg.png"/>
            </div>
        </div>

        <div class="ui hidden divider"></div>

        <div class="required field">
            <label>How did you find out about us?</label>
            <div class="two fields">
                <div class="field">
                    <label><em>Advertisement</em></label>
                    <input ref="advertisementinput" type="text" v-model.trim="form.advertisement"/>
                </div>
                <div class="field">
                    <label><em>Insert / Pamphlet</em></label>
                    <input type="text" v-model.trim="form.insert_pamphlet"/>
                </div>
            </div>
            <div class="two fields">
                <div class="field">
                    <label><em>Promotional Offer</em></label>
                    <input type="text" v-model.trim="form.promotional_offer"/>
                </div>
                <div class="field">
                    <label><em>Family / Friend</em></label>
                    <input type="text" v-model.trim="form.family_friend" placeholder="Name / Tel"/>
                </div>
            </div>
            <div class="two fields">
                <div class="field">
                    <label><em>Other</em></label>
                    <input type="text" v-model.trim="form.other_findout"/>
                </div>
            </div>
        </div>

        <div class="ui hidden divider"></div>

        <combo-dropdown label="How often do you take a facial treatment?" secondary-label="Please Specify" :selection="facial_treatment_frequencies" other-value="others" v-model="form.facial_treatment_frequency" required/>

        <div class="ui hidden divider"></div>

        <div class="required field">
            <label>What brand of skin care products are you currently using?</label>
            <div class="two fields">
                <div class="field">
                    <label><em>Cleanser</em></label>
                    <input ref="cleanserinput" type="text" v-model.trim="form.cleanser_brand"/>
                </div>
                <div class="field">
                    <label><em>Toner</em></label>
                    <input type="text" v-model.trim="form.toner_brand"/>
                </div>
            </div>
            <div class="two fields">
                <div class="field">
                    <label><em>Moisturizer</em></label>
                    <input type="text" v-model.trim="form.moisturizer_brand"/>
                </div>
                <div class="field">
                    <label><em>Sun Cream</em></label>
                    <input type="text" v-model.trim="form.suncream_brand"/>
                </div>
            </div>
            <div class="two fields">
                <div class="field">
                    <label><em>Serum</em></label>
                    <input type="text" v-model.trim="form.serum_brand"/>
                </div>
                <div class="field">
                    <label><em>Mask Care</em></label>
                    <input type="text" v-model.trim="form.mask_care_brand"/>
                </div>
            </div>
            <div class="two fields">
                <div class="field">
                    <label><em>Other</em></label>
                    <input type="text" v-model.trim="form.other_skincare_brand"/>
                </div>
            </div>
        </div>

        <div class="ui hidden divider"></div>

        <div class="required field">
            <label>What are the concerns you have for your <strong><em>skin</em></strong>?</label>
            <div class="four fields">
                <div class="field">
                    <div class="ui checkbox">
                        <input ref="skinconcerninput" type="checkbox" tabindex="0" class="hidden" value="breakouts/acne" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['breakouts/acne']")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="blackheads/whiteheads" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['blackheads/whiteheads']")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="excessive oil/shine" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['excessive oil/shine']")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="dull/dry skin" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['dull/dry skin']")}}</em></label>
                    </div>
                </div>
            </div>
            <div class="four fields">
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="broken capillaries" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['broken capillaries']")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="redness/ruddiness" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['redness/ruddiness']")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="flaky skin" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['flaky skin']")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="brown spot/pigmentation" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['brown spot/pigmentation']")}}</em></label>
                    </div>
                </div>
            </div>
            <div class="four fields">
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="uneven skin tone" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['uneven skin tone']")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="wrinkles/fine lines" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['wrinkles/fine lines']")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="open pores" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['open pores']")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="deep scars" v-model="form.skin_concerns"/>
                        <label><em>{{$t("skin_concern['deep scars']")}}</em></label>
                    </div>
                </div>
            </div>

            <div class="four fields">
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" v-model="has_other_skin_concern" @change="changeOtherSkinConcern"/>
                        <label><em>Other</em></label>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui hidden divider"></div>

        <div class="required field" v-if="has_other_skin_concern">
            <label><em>Please specify <strong><em>other</em></strong> concerns you have for your <strong><em>skin</em></strong></em></label>
            <input type="text" v-model.trim="form.other_skin_concern" required/>
        </div>

        <div class="ui hidden divider"></div>

        <div class="required field">
            <label>What are the concerns you have for your <strong><em>eyes</em></strong>?</label>
            <div class="four fields">
                <div class="field">
                    <div class="ui checkbox">
                        <input ref="eyeconcerninput" type="checkbox" tabindex="0" class="hidden" value="dehydrated" v-model="form.eye_concerns"/>
                        <label><em>{{$t("eye_concern.dehydrated")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="wrinkles" v-model="form.eye_concerns"/>
                        <label><em>{{$t("eye_concern.wrinkles")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="puffiness" v-model="form.eye_concerns"/>
                        <label><em>{{$t("eye_concern.puffiness")}}</em></label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" value="dark circles" v-model="form.eye_concerns"/>
                        <label><em>{{$t("eye_concern['dark circles']")}}</em></label>
                    </div>
                </div>
            </div>
            <div class="four fields">
                <div class="field">
                    <div class="ui checkbox">
                        <input type="checkbox" tabindex="0" class="hidden" v-model="has_other_eye_concern" @change="changeOtherEyeConcern"/>
                        <label><em>Other</em></label>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui hidden divider"></div>

        <div class="required field" v-if="has_other_eye_concern">
            <label><em>Please specify <strong><em>other</em></strong> concerns you have for your <strong><em>eyes</em></strong></em></label>
            <input type="text" v-model.trim="form.other_eye_concern" required/>
        </div>

        <div class="ui hidden divider"></div>

        <div class="field">
            <label>Have you had an allergic reaction to any product or skincare treatment?</label>
            <label><em>Is yes, please specify below (leave blank if none).</em></label>
            <input type="text" v-model.trim="form.allergy"/>
        </div>

        <template v-if="actions">
            <div class="ui hidden divider"></div>

            <div class="ui two column grid">
                <div class="column">
                    <button type="button" class="ui red alt button" :class="{loading: submitting}" :disabled="submitting" @click="cancel" v-if="actions.cancel">
                        {{actions.cancel.text || "Cancel"}}
                    </button>
                </div>
                <div class="right aligned column">
                    <button type="button" class="ui teal alt button" :class="{loading: submitting}" :disabled="submitting" @click="prev" v-if="actions.prev">
                        <i class="fontello-left" style="margin-right: 0.5em;"></i> {{actions.prev.text || "Prev"}}
                    </button>
                    <button type="submit" class="ui teal button" :class="{loading: submitting}" :disabled="submitting" v-if="actions.next">
                        {{actions.next.text || "Next"}} <i class="fontello-right" style="margin-left: 0.5em;"></i>
                    </button>
                </div>
            </div>
        </template>

    </form>
</template>

<script>
import todayvisitMessage from "@/i18n/consultation/todayvisit";
const ComboDropdown = () => import("@/components/ComboDropdown");

export default {
    name: "personal-info-form",
    components: {
        ComboDropdown
    },
    i18n: {
        sharedMessages: todayvisitMessage
    },
    props: {
        actions: {
            type: Object
        },
        formdata: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        const blank_form = {
            advertisement: "",
            insert_pamphlet: "",
            promotional_offer: "",
            family_friend: "",
            other_findout: "",
            facial_treatment_frequency: "",
            cleanser_brand: "",
            toner_brand: "",
            moisturizer_brand: "",
            suncream_brand: "",
            serum_brand: "",
            mask_care_brand: "",
            other_skincare_brand: "",
            skin_concerns: [],
            other_skin_concern: "",
            eye_concerns: [],
            other_eye_concern: "",
            allergy: ""
        };

        return {
            facial_treatment_frequencies: {
                "once_a_week": this.$t("facial_treatment_frequency.once_a_week"),
                "twice_a_month": this.$t("facial_treatment_frequency.twice_a_month"),
                "once_a_month": this.$t("facial_treatment_frequency.once_a_month"),
                "others": "Others"
            },
            blank_form,
            form: Object.assign({}, blank_form),
            has_other_skin_concern: false,
            has_other_eye_concern: false,
            submitting: false
        };
    },
    mounted() {
        $(this.$el).find(".ui.checkbox").checkbox();
    },
    computed: {
        customer_name() {
            return this.formdata?.profile?.name || "";
        }
    },
    methods: {
        submitForm() {
            this.submitting = true;
            this.$toasted.clear();

            //manual validation
            if(!this.form.advertisement && !this.form.insert_pamphlet && !this.form.promotional_offer && !this.form.family_friend && !this.form.other_findout) {
                this.$toasted.show("Please tell us how did you find out about us.", {
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            setTimeout(() => {
                                this.submitting = false;
                                $(this.$refs.advertisementinput).focus();
                            }, 0);

                            toast.goAway(0);
                        }
                    },
                    onComplete: () => {
                        this.submitting = false;
                        $(this.$refs.advertisementinput).focus();
                    }
                });

                return false;
            }

            //manual validation
            if(!this.form.cleanser_brand
                && !this.form.toner_brand
                && !this.form.moisturizer_brand
                && !this.form.suncream_brand
                && !this.form.serum_brand
                && !this.form.mask_care_brand
                && !this.form.other_skincare_brand) {
                this.$toasted.show("Please tell us what brand of skin care products are you currently using.", {
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            setTimeout(() => {
                                this.submitting = false;
                                $(this.$refs.cleanserinput).focus();
                            }, 0);

                            toast.goAway(0);
                        }
                    },
                    onComplete: () => {
                        this.submitting = false;
                        $(this.$refs.cleanserinput).focus();
                    }
                });

                return false;
            }

            //manual validation
            if(!this.form.skin_concerns.length && !this.form.other_skin_concern) {
                this.$toasted.show("Please tell us what are the concerns you have for your skin.", {
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            setTimeout(() => {
                                this.submitting = false;
                                $(this.$refs.skinconcerninput).focus();
                            }, 0);

                            toast.goAway(0);
                        }
                    },
                    onComplete: () => {
                        this.submitting = false;
                        $(this.$refs.skinconcerninput).focus();
                    }
                });

                return false;
            }

            //manual validation
            if(!this.form.eye_concerns.length && !this.form.other_eye_concern) {
                this.$toasted.show("Please tell us what are the concerns you have for your eyes.", {
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            setTimeout(() => {
                                this.submitting = false;
                                $(this.$refs.eyeconcerninput).focus();
                            }, 0);

                            toast.goAway(0);
                        }
                    },
                    onComplete: () => {
                        this.submitting = false;
                        $(this.$refs.eyeconcerninput).focus();
                    }
                });

                return false;
            }

            this.$emit("submit", this.form);
        },
        prev() {
            this.$emit("prev", this.form);
        },
        cancel() {
            this.$emit("cancel");
        },
        changeOtherSkinConcern() {
            if(!this.has_other_skin_concern) {
                this.form.other_skin_concern = "";
            }
        },
        changeOtherEyeConcern() {
            if(!this.has_other_eye_concern) {
                this.form.other_eye_concern = "";
            }
        }
    },
    watch: {
        formdata: {
            deep: true,
            immediate: true,
            handler() {
                if(!this.isBlankObject(this.formdata)) {
                    this.form = Object.assign({}, this.blank_form, this.formdata);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/consultationform.scss";

.ui.form {
    .ui.other-checkbox {
        display: flex;

        .ui.checkbox {
            flex: 1 0 auto;
            margin-right: 0.5em;
        }

        input[type="text"] {
            padding: 0;
            border: none;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .ui.form:not(.unstackable) .four.fields:not(.unstackable) {
        flex-wrap: wrap;
        margin-bottom: 0;

        > .field {
            width: 50%;
            margin: 0 0 1em;
        }
    }
}
</style>