<template>
    <form class="ui form" :class="{loading: submitting}" @submit.prevent="submitForm">
        <div class="ui middle aligned grid">
            <div class="sixteen wide mobile eight wide tablet eight wide computer column">
                <h3 class="ui header">
                    <template v-if="form.id">
                        Edit Consultation
                        <div class="ui fitted hidden divider"></div>
                        <div class="sub header"><strong class="ui purple text">{{customer_name}}</strong></div>
                    </template>
                    <template v-else>
                        Thanks {{customer_name}}
                        <div class="sub header">Lastly, we just need some basic information of your medical history and lifestyle</div>
                    </template>
                </h3>
            </div>
            <div class="computer only tablet only right aligned eight wide column">
                <img class="logo" src="~@/assets/images/logo/1991spwg.png"/>
            </div>
        </div>

        <div class="ui hidden divider"></div>

        <ol class="field-list">
            <h4>MEDICAL HISTORY</h4>
            <li>
                <div class="field">
                    <label>Have you had any surgery / cosmetic surgery?</label>
                    <label><em>Is yes, please specify below (leave blank if none).</em></label>
                    <input type="text" v-model.trim="form.surgery"/>
                </div>
            </li>
            <li>
                <div class="checkbox field">
                    <label>Have you been to any Dermatologist?</label>
                    <toggle-button v-model="form.visited_dermatology" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
                </div>
            </li>
            <li>
                <div class="field">
                    <label>Are you on any controlled medication now?</label>
                    <label><em>Is yes, please specify below (leave blank if none).</em></label>
                    <input type="text" v-model.trim="form.controlled_medication"/>
                </div>
            </li>
            <li>
                <div class="field">
                    <label>Please tick if you have been on any of the acne medications below</label>
                    <div class="four fields">
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" tabindex="0" class="hidden" value="retin a" v-model="form.acne_medications"/>
                                <label><em>{{$t("acne_medication['retin a']")}}</em></label>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" tabindex="0" class="hidden" value="accutane" v-model="form.acne_medications"/>
                                <label><em>{{$t("acne_medication['accutane']")}}</em></label>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="field">
                    <label>Do you have any of the following health conditions?</label>
                    <div class="four fields">
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" tabindex="0" class="hidden" value="eczema" v-model="form.health_conditions"/>
                                <label><em>{{$t("health_condition['eczema']")}}</em></label>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" tabindex="0" class="hidden" value="blood pressure" v-model="form.health_conditions"/>
                                <label><em>{{$t("health_condition['blood pressure']")}}</em></label>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" tabindex="0" class="hidden" value="diabetic" v-model="form.health_conditions"/>
                                <label><em>{{$t("health_condition['diabetic']")}}</em></label>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" tabindex="0" class="hidden" value="heart problem" v-model="form.health_conditions"/>
                                <label><em>{{$t("health_condition['heart problem']")}}</em></label>
                            </div>
                        </div>
                    </div>
                    <div class="four fields">
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" tabindex="0" class="hidden" v-model="has_other_health_condition" @change="changeOtherHealthCondition"/>
                                <label><em>Other</em></label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="required field" v-if="has_other_health_condition">
                    <label><em>Please specify <strong><em>other</em></strong> health conditions</em></label>
                    <input type="text" v-model.trim="form.other_health_condition" required/>
                </div>
            </li>
            <div class="ui hidden divider"></div>
            <h4>LIFESTYLE</h4>
            <li>
                <div class="checkbox field">
                    <label>Do you exercise regularly?</label>
                    <toggle-button v-model="form.exercise_regularly" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
                </div>
            </li>
            <li>
                <div class="checkbox field">
                    <label>Does your diet contain a lot of carbohydrate?</label>
                    <toggle-button v-model="form.high_carbohydrate_diet" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
                </div>
            </li>
            <li>
                <div class="field">
                    <label>How many hours do you sleep every night?</label>
                    <div class="inline field">
                        <input type="text" v-model.trim="form.sleep_hours"/> <em>hours</em>
                    </div>
                </div>
            </li>
            <div class="ui hidden divider"></div>
            <h4>SENSITIVITY</h4>
             <li>
                <div class="checkbox field">
                    <label>Does your face get sun-burned easily?</label>
                    <toggle-button v-model="form.sunburn_prone" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
                </div>
            </li>
            <li>
                <div class="field">
                    <label>Have you done any laser / peeling?</label>
                    <label><em>Is yes, please specify below (leave blank if none).</em></label>
                    <input type="text" v-model.trim="form.laser_peeling"/>
                </div>
                <!-- <div class="checkbox field">
                    <label>Have you done any laser / peeling?</label>
                    <div class="ui toggle checkbox">
                        <input type="checkbox" v-model="had_laser_peeling"/>
                        <label></label>
                    </div>
                </div>
                <div class="field" v-if="had_laser_peeling">
                    <label><em>Is yes, please specify</em></label>
                    <input type="text" v-model.trim="form.laser_peeling" required/>
                </div> -->
            </li>
            <div class="ui hidden divider"></div>
            <h4>HYDRATION</h4>
            <li>
                <div class="field">
                    <label>How much plain water do you consume daily?</label>
                    <div class="inline field">
                        <input type="text" v-model.trim="form.daily_water_consumption"/> <em>liters</em>
                    </div>
                </div>
            </li>
            <li>
                <div class="checkbox field">
                    <label>Do you consume alcoholic beverages frequently?</label>
                    <toggle-button v-model="form.frequent_alcoholic" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
                </div>
            </li>
            <li>
                <div class="checkbox field">
                    <label>Does your face show obvious dryness/tightness?</label>
                    <toggle-button v-model="form.obvious_dryness_tightness" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
                </div>
            </li>
            <li>
                <div class="checkbox field">
                    <label>Do you take alcohol/coffee/tea/carbonated drinks?</label>
                    <toggle-button v-model="form.alcohol_coffee_tea_carbonated" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
                </div>
            </li>
            <div class="ui hidden divider"></div>
            <h4>SEBUM</h4>
            <li>
                <div class="checkbox field">
                    <label>Does your face feel oily during the day?</label>
                    <toggle-button v-model="form.daytime_oily_face" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
                </div>
            </li>
            <li>
                <div class="checkbox field">
                    <label>Do you experience skin break-outs?</label>
                    <toggle-button v-model="form.skin_breakout" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
                </div>
            </li>
            <div class="ui hidden divider"></div>
            <h4>TREATMENT PREFERENCE</h4>
            <li>
                <div class="required field">
                    <label>Preferred massage technique?</label>
                    <div class="four fields">
                        <div class="field">
                            <div class="ui radio checkbox">
                                <input ref="preferredmassagetechnique" type="radio" name="preferred_massage_technique" value="light" v-model="form.preferred_massage_technique"/>
                                <label>{{$t("preferred_massage_technique['light']")}}</label>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui radio checkbox">
                                <input type="radio" name="preferred_massage_technique" value="firm" v-model="form.preferred_massage_technique"/>
                                <label>{{$t("preferred_massage_technique['firm']")}}</label>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui radio checkbox">
                                <input type="radio" name="preferred_massage_techniqus" value="heavy" v-model="form.preferred_massage_technique"/>
                                <label>{{$t("preferred_massage_technique['heavy']")}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="required field">
                    <label>Preferred extraction technique?</label>
                    <div class="four fields">
                        <div class="field">
                            <div class="ui radio checkbox">
                                <input ref="preferredextractiontechnique" type="radio" name="preferred_extraction_technique" value="thorough" v-model="form.preferred_extraction_technique"/>
                                <label>{{$t("preferred_extraction_technique['thorough']")}}</label>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui radio checkbox">
                                <input type="radio" name="preferred_extraction_technique" value="light" v-model="form.preferred_extraction_technique"/>
                                <label>{{$t("preferred_extraction_technique['light']")}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <template v-if="formdata.profile.gender === $gender.FEMALE">
                <div class="ui hidden divider"></div>
                <h4>FOR FEMALE CLIENTS ONLY</h4>
                <li>
                    <div class="checkbox field">
                        <label>Are you pregnant or trying to conceive?</label>
                        <toggle-button v-model="form.pregnant_conceive" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
                    </div>
                </li>
            </template>
        </ol>

        <template v-if="actions">
            <div class="ui hidden divider"></div>

            <div class="ui two column grid">
                <div class="column">
                    <button type="button" class="ui red basic button" :class="{loading: submitting}" :disabled="submitting" @click="cancel" v-if="actions.cancel">
                        {{actions.cancel.text || "Cancel"}}
                    </button>
                </div>
                <div class="right aligned column">
                    <button type="button" class="ui teal basic button" :class="{loading: submitting}" :disabled="submitting" @click="prev" v-if="actions.prev">
                        <i class="fontello-left" style="margin-right: 0.5em;"></i> {{actions.prev.text || "Prev"}}
                    </button>
                    <button type="submit" class="ui teal button" :class="{loading: submitting}" :disabled="submitting" v-if="actions.next">
                        {{actions.next.text || "Next"}} <i class="fontello-right" style="margin-left: 0.5em;"></i>
                    </button>
                </div>
            </div>
        </template>

    </form>
</template>

<script>
import medicalinfoMessage from "@/i18n/consultation/medicalinfo";

import { ToggleButton } from "vue-js-toggle-button";

export default {
    name: "medical-info-form",
    components: {
        ToggleButton
    },
    i18n: {
        sharedMessages: medicalinfoMessage
    },
    props: {
        actions: {
            type: Object
        },
        formdata: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        const blank_form = {
            surgery: "",
            visited_dermatology: false,
            controlled_medication: "",
            acne_medications: [],
            health_conditions: [],
            other_health_condition: "",
            exercise_regularly: false,
            high_carbohydrate_diet: false,
            sleep_hours: "",
            sunburn_prone: false,
            laser_peeling: "",
            daily_water_consumption: "",
            frequent_alcoholic: false,
            obvious_dryness_tightness: false,
            alcohol_coffee_tea_carbonated: false,
            daytime_oily_face: false,
            skin_breakout: false,
            preferred_massage_technique: "",
            preferred_extraction_technique: "",
            pregnant_conceive: false
        };

        return {
            blank_form,
            form: Object.assign({}, blank_form),
            toggle_checkbox: {
                color: "#0cc2da",
                width: 64,
                height: 28,
                labels: {
                    checked: "YES",
                    unchecked: "NO"
                }
            },
            has_other_health_condition: false,
            submitting: false
        };
    },
    mounted() {
        $(this.$el).find(".ui.checkbox").checkbox();
    },
    computed: {
        customer_name() {
            return this.formdata?.profile?.name || "";
        }
    },
    methods: {
        submitForm() {
            this.submitting = true;

            if(!this.form.preferred_massage_technique) {
                this.$toasted.show("Please tell us your preferred massage technique.", {
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            setTimeout(() => {
                                this.submitting = false;
                                $(this.$refs.preferredmassagetechnique).focus();
                            }, 0);

                            toast.goAway(0);
                        }
                    },
                    onComplete: () => {
                        this.submitting = false;
                        $(this.$refs.preferredmassagetechnique).focus();
                    }
                });

                return false;
            }

            if(!this.form.preferred_extraction_technique) {
                this.$toasted.show("Please tell us your preferred extraction technique.", {
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            setTimeout(() => {
                                this.submitting = false;
                                $(this.$refs.preferredextractiontechnique).focus();
                            }, 0);

                            toast.goAway(0);
                        }
                    },
                    onComplete: () => {
                        this.submitting = false;
                        $(this.$refs.preferredextractiontechnique).focus();
                    }
                });

                return false;
            }

            this.$emit("submit", this.form);
        },
        prev() {
            this.$emit("prev", this.form);
        },
        cancel() {
            this.$emit("cancel");
        },
        changeOtherHealthCondition() {
            if(!this.has_other_health_condition) {
                this.form.other_health_condition = "";
            }
        }
    },
    watch: {
        formdata: {
            deep: true,
            immediate: true,
            handler() {
                if(!this.isBlankObject(this.formdata)) {
                    this.form = Object.assign({}, this.blank_form, this.formdata);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/consultationform.scss";

.checkbox.field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
}

.ui.toggle.checkbox {
    label, .box {
        min-height: 28px;

        &:before {
            font-family: monospace;
            color: #fff;
            content: "NO";
            text-align: right;
            height: 28px;
            line-height: 28px;
            width: 58px;
            padding-right: 10px;
            background: rgba(0,0,0,.3);
            font-size: 0.9rem;
        }

        &:after {
            top: 2px;
            left: 2px;
            width: 24px;
            height: 24px;
        }
    }

    input {
        height: 28px;
        width: 58px;

        &:checked {
            ~label, ~.box {
                &:before {
                    content: "YES";
                    text-align: left;
                    padding-left: 7px;
                    background: #3498db !important;
                }

                &:after {
                    left: 32px;
                }
            }
        }
    }
}

.field-list {
    padding-left: 0;

    li {
        margin-left: 1.5em;

        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}
</style>