export default {
    en: {
        facial_treatment_frequency: {
            once_a_week: "Once a week",
            twice_a_month: "Twice a month",
            once_a_month: "Once a month",
            others: "Others"
        },
        skin_concern: {
            "breakouts/acne": "Breakouts / Acne",
            "blackheads/whiteheads": "Blackheads / Whiteheads",
            "excessive oil/shine": "Excessive Oil / Shine",
            "dull/dry skin": "Dull / Dry Skin",
            "broken capillaries": "Broken capillaries",
            "redness/ruddiness": "Redness / Ruddiness",
            "flaky skin": "Flaky skin",
            "brown spot/pigmentation": "Brown Spot / Pigmentation",
            "uneven skin tone": "Uneven skin tone",
            "wrinkles/fine lines": "Wrinkles / Fine lines",
            "open pores": "Open Pores",
            "deep scars": "Deep Scars"
        },
        eye_concern: {
            "dehydrated": "Dehydrated",
            "wrinkles": "Wrinkles",
            "puffiness": "Puffiness",
            "dark circles": "Dark circles"
        }
    }
};