export default {
    en: {
        acne_medication: {
            "retin a": "Retin A",
            "accutane": "Accutane"
        },
        health_condition: {
            "eczema": "Eczema",
            "blood pressure": "High or low blood pressure",
            "diabetic": "Diabetic",
            "heart problem": "Heart problem"
        },
        preferred_massage_technique: {
            "light": "Light",
            "firm": "Firm",
            "heavy": "Heavy"
        },
        preferred_extraction_technique: {
            "thorough": "Thorough",
            "light": "Light"
        }
    }
};